import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-container">
        <img
          alt={props.imageAlt}
          src="/prop-cost-1500h.png"
          className="footer-image"
        />
        <span className="dark-text footer-text">{props.text}</span>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  imageAlt: 'logo',
  text: '© 2024 Prop Cost, All Rights Reserved.',
  rootClassName: '',
}

Footer.propTypes = {
  imageAlt: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Footer
