import React from 'react'

import PropTypes from 'prop-types'

import './blog.css'

const Blog = (props) => {
  return (
    <div className="blog-blog">
      <div className="blog-container">
        <div className="blog-blog-post-card">
          <img
            alt={props.imageAlt}
            src={props.imageSrc}
            className="blog-image"
          />
          <div className="blog-container1">
            <span className="blog-text">{props.text1}</span>
            <span className="blog-text001">
              <span>
                Understanding the costs involved in buying and owning a home can
                be complex. We have compiled a list of all the costs associated
                with the buying process and ownership. This guide simplifies
                each cost into a brief description with an average or range to
                help you navigate your journey.
              </span>
              <br></br>
              <br></br>
              <span>
                All averages and ranges should not be taken as definitive and
                exist to give a general idea. Costs can vary widely based on
                location, property type, and other factors. It&apos;s important
                to research local costs and consult with professionals to get a
                more accurate estimate.
              </span>
              <br></br>
              <br></br>
              <span>
                All of these costs listed below are factored into our analysis
                tool to provide a comprehensive view of the financial
                implications of home ownership.
              </span>
              <br></br>
              <br></br>
              <span className="blog-text011">Basics</span>
              <br></br>
              <br></br>
              <span>
                These are some foundational parameters that are used in our
                analysis:
              </span>
              <br></br>
              <br></br>
              <span>  - Sale Price: The purchase price of the home.</span>
              <br></br>
              <span>
                  - Inflation Rate: Measures how much the value of money
                decreases over time. The American average from 2014 to 2023 was
                2.87%.
              </span>
              <br></br>
              <span>
                  - Yearly Income: Your annual income which determines how much
                you will be able to save on taxes.
              </span>
              <br></br>
              <br></br>
              <span className="blog-text024">Loan</span>
              <br></br>
              <br></br>
              <span>
                The financial aspects of borrowing money to purchase a home:
              </span>
              <br></br>
              <br></br>
              <span>
                  - Loan Amount: The amount of money borrowed to purchase the
                home.
              </span>
              <br></br>
              <span>
                  - Loan Interest Rate: The cost of borrowing money for your
                mortgage. The current averaging is about 6.875%. This frequently
                changes and can vary based on credit score and other factors.
              </span>
              <br></br>
              <span>
                  - Loan Duration: The length of time over which the loan will
                be repaid. The most common loan duration is 30 years.
              </span>
              <br></br>
              <br></br>
              <span className="blog-text037">Buying Costs</span>
              <br></br>
              <br></br>
              <span>
                One-time costs associated with the purchase of a home:
              </span>
              <br></br>
              <br></br>
              <span>
                  - Down Payment: The initial payment made when purchasing the
                home. The average down payment is 20% of the sale price.
              </span>
              <br></br>
              <span>
                  - Title Search: Verifies the seller has the right to sell the
                property. The average cost ranges from $200 to $400.
              </span>
              <br></br>
              <span>
                  - Appraisal Fee: Assesses the home&apos;s value for the
                lender.The average cost is $300 to $500.
              </span>
              <br></br>
              <span>
                  - General Inspection Fee: Evaluates the condition of the home.
                This typically ranges from $400 - $1,000.
              </span>
              <br></br>
              <span>
                  - Recording Fee: Charges for recording the deed and mortgage.
                Averages $20 - $200.
              </span>
              <br></br>
              <span>
                  - Loan Origination Fee: Covers the lender&apos;s
                administrative costs. This is typically about 0.75% of the loan
                amount.
              </span>
              <br></br>
              <span>
                  - Survey Fee: Determines property boundaries. Cost anywhere
                from $250 - $1,000 but is not always required.
              </span>
              <br></br>
              <span>
                  - Settlement Fee: Paid at closing for various processing
                services. Averages $300, but can vary widely.
              </span>
              <br></br>
              <span>
                  - Title Insurance: Protects against future claims to
                ownership. Averages around 0.5% - 1% of the sale price.
              </span>
              <br></br>
              <br></br>
              <span className="blog-text062">Expenses</span>
              <br></br>
              <br></br>
              <span>
                Recurring costs of owning a home. These costs are typically paid
                monthly or annually.
              </span>
              <br></br>
              <br></br>
              <span>
                  - Property Tax: Annual tax based on property value. This
                varies widely by location, research your local rates.
              </span>
              <br></br>
              <span>
                  - Home Insurance: Protects against damages. Averages $1,300
                per year (2023) but can vary widely.
              </span>
              <br></br>
              <span>
                  - Repairs: Ongoing maintenance costs. It can be a good idea to
                budget 1%- 4% of the home&apos;s value annually for repairs.
              </span>
              <br></br>
              <span>  - Electricity: Average monthly cost of $115.</span>
              <br></br>
              <span>  - Heating: Average monthly cost of $80.</span>
              <br></br>
              <span>  - Water: Average monthly cost of $75.</span>
              <br></br>
              <span>  - Internet: Average monthly cost of $75.</span>
              <br></br>
              <span>  - Sewer: Average monthly cost of $40.</span>
              <br></br>
              <span>  - Garbage: Average monthly cost of $30.</span>
              <br></br>
              <span>
                  - HOA Fees: Monthly fees for community maintenance. Many
                neighborhoods have no HOA fees.
              </span>
              <br></br>
              <br></br>
              <span className="blog-text089">Gains</span>
              <br></br>
              <br></br>
              <span>The financial benefits of owning a home:</span>
              <br></br>
              <br></br>
              <span>
                  - Property Appreciation Rate: The rate at which the
                property&apos;s value increases; historically averages 3.9% over
                25 years.
              </span>
              <br></br>
              <span>
                  - Tax Deductions: Mortgage interest and property tax
                deductions can reduce your taxable income.
              </span>
              <br></br>
              <span> </span>
              <br></br>
              <span className="blog-text101">Selling Costs</span>
              <br></br>
              <span> </span>
              <br></br>
              <span>Expenses when selling your home:</span>
              <br></br>
              <br></br>
              <span>
                  - Realtor Fee: Commission for selling the property; averages
                6% of sale price (range: 5% - 6%).
              </span>
              <br></br>
              <span>
                  - Pre Sale Inspection Fee: Ensures the property is in good
                condition before sale. Averages $300 - $500.
              </span>
              <br></br>
              <span>
                  - Staging: Optionally enhances the home&apos;s appeal to
                buyers. Costs vary but roughly $2,000 can be expected.
              </span>
              <br></br>
              <span>
                  - Settlement Fees: Covers the cost of closing the sale. Around
                $500 on average.
              </span>
              <br></br>
              <br></br>
              <span>
                This streamlined guide aims to make the cost aspects of home
                buying more accessible, helping you make informed decisions on
                your path to home ownership.
              </span>
              <br></br>
              <br></br>
              <span>
                This streamlined guide aims to educate you on the parameters
                that go into our analysis and how you can customize them to fit
                your specific situation.
              </span>
              <br></br>
            </span>
            <div className="blog-container2">
              <div className="blog-profile">
                <img
                  alt={props.imageAlt1}
                  src={props.imageSrc1}
                  image_src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&amp;ixlib=rb-1.2.1&amp;h=1200"
                  className="blog-image1"
                />
              </div>
              <span className="blog-text122">{props.text2}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-container3"></div>
      <div className="blog-container4"></div>
    </div>
  )
}

Blog.defaultProps = {
  text1: 'Complete Home Buying Cost Guide',
  imageSrc:
    'https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzfHxob3VzZXxlbnwwfHx8fDE3MTI0NDE3NjR8MA&ixlib=rb-4.0.3&w=1200',
  text2: '5 min read',
  imageAlt1: 'profile',
  imageSrc1: '/prop-cost-200h.png',
  imageAlt: 'image',
  text: 'Guide',
}

Blog.propTypes = {
  text1: PropTypes.string,
  imageSrc: PropTypes.string,
  text2: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt: PropTypes.string,
  text: PropTypes.string,
}

export default Blog
