import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Blog from '../components/blog'
import Footer from '../components/footer'
import './cost-guide.css'

const CostGuide = (props) => {
  return (
    <div className="cost-guide-container">
      <Helmet>
        <title>Cost-Guide - Prop Cost</title>
        <meta
          name="description"
          content="The most in depth home buying and home ownership cost analysis tool designed for home buyers and realtors."
        />
        <meta property="og:title" content="Cost-Guide - Prop Cost" />
        <meta
          property="og:description"
          content="The most in depth home buying and home ownership cost analysis tool designed for home buyers and realtors."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <Blog></Blog>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default CostGuide
