import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Hero from '../components/hero'
import InfoHero from '../components/info-hero'
import Features from '../components/features'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Prop Cost</title>
        <meta
          name="description"
          content="The most in depth home buying and home ownership cost analysis tool designed for home buyers and realtors."
        />
        <meta property="og:title" content="Prop Cost" />
        <meta
          property="og:description"
          content="The most in depth home buying and home ownership cost analysis tool designed for home buyers and realtors."
        />
      </Helmet>
      <Header></Header>
      <Hero></Hero>
      <InfoHero></InfoHero>
      <Features></Features>
      <Footer></Footer>
    </div>
  )
}

export default Home
