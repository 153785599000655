import React from 'react'

import PropTypes from 'prop-types'

import './info-hero.css'

const InfoHero = (props) => {
  return (
    <div id="info-hero" className="info-hero-hero">
      <div className="info-hero-container">
        <h1 className="info-hero-text dark-text">{props.heading}</h1>
        <span className="info-hero-text1">{props.text}</span>
        <span className="info-hero-text2">{props.text1}</span>
        <a
          href="https://app.prop-cost.com/dash-buyer"
          className="info-hero-link button"
        >
          {props.button}
        </a>
      </div>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="info-hero-image"
      />
    </div>
  )
}

InfoHero.defaultProps = {
  imageSrc: '/prop-cost-demo-graphic-700w.png',
  imageAlt: 'image',
  heading: 'Know All The Costs',
  button: 'Get Started',
  text: 'Are you buying a home or deciding whether to buy?  Use our analysis tool to get a complete understanding of home buying and home ownership costs.',
  text1: 'Tailor your analysis to perfectly match your scenario.',
}

InfoHero.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  heading: PropTypes.string,
  button: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
}

export default InfoHero
