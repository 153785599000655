import React from 'react'

import PropTypes from 'prop-types'

import './hero.css'

const Hero = (props) => {
  return (
    <div className="hero-hero">
      <div className="hero-container">
        <div className="hero-container1">
          <h1 className="hero-heading dark-text">
            <span className="hero-text">
              Unlock the
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="hero-text1">true cost</span>
            <br className="hero-text2"></br>
            <span className="hero-text3">of home ownership</span>
          </h1>
          <span className="hero-text4 dark-text">{props.text}</span>
          <div className="hero-btn-group">
            <a href="#info-hero" className="hero-link button">
              {props.button}
            </a>
            <a
              href="https://app.prop-cost.com/dash-buyer"
              className="hero-link1 button"
            >
              {props.button1}
            </a>
          </div>
        </div>
        <img
          alt={props.imageAlt}
          src="/propcostherolarge%20(1).png"
          loading="eager"
          className="hero-image"
        />
      </div>
    </div>
  )
}

Hero.defaultProps = {
  imageAlt: 'image',
  imageSrc: '1074abd6-c284-463e-8079-18133308ae21',
  text: 'The most in depth home ownership cost analysis tool designed for home buyers and realtors.',
  button: 'LEARN MORE',
  button1: 'TRY NOW',
}

Hero.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.string,
  button1: PropTypes.string,
}

export default Hero
